import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import { PostStoreCtx } from '../stores/posts';
import Post from './Post';
import './PostStream.css';

export default observer(() => {
    const store = useContext(PostStoreCtx);

    if (store.posts.length === 0 && !store.isLoading && !store.loadError)
        store.loadMore();

    return (
        <div className='postStream'>
            {store.posts.map((x) => <Post key={x.id} {...x} />)}

            {store.isLoading ? <p className='loading'>Loading...</p> : ''}
            {store.loadError ? <p className='error'>Error: {store.loadError.toString()}</p> : ''}

            {(store.hasMore && !store.isLoading && !store.loadError) ? <button className='loadMore' onClick={e => {
                e.preventDefault();
                store.loadMore();
            }}>Load more</button> : <p className='end'>That's all folks!</p>}
        </div>
    );
});