import './Post.css';
import Embed from './Embed';

const Post = ({ username, title, content, avatar, embed }) => {
    return (
        <article className='postContainer'>
            <div className='avatar' dangerouslySetInnerHTML={({ __html: avatar })} alt={username} />
            <div className="post">
                <span className='username'>{username}</span>
                <h1 className='title'>{title}</h1>
                <p className='content'>{content}</p>
                {embed ? <Embed {...embed} /> : ''}
            </div>
        </article>
    )
}

export default Post