import React from 'react';
import CreatePost from '../components/CreatePost';
import PostStream from '../components/PostStream';

const Posts = () => {
    return (
        <div>
            <CreatePost />

            <PostStream />
        </div>
    );
}

export default Posts