import Posts from './pages/Posts';
import NotFound from './pages/NotFound';
import { Router } from "@reach/router";
import { PostStore, PostStoreCtx } from './stores/posts';
import './App.css';

function App() {
  return (
    <PostStoreCtx.Provider value={new PostStore()}>
      <main>
        <Router>
          <Posts path="/" />
          <NotFound default />
        </Router>
      </main>
    </PostStoreCtx.Provider>

  );
}

export default App;
