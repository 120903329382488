import React from 'react';

import { PostStoreCtx } from '../stores/posts';
import './CreatePost.css';

export default class CreatePost extends React.Component {
    constructor(props) {
        super(props);

        this.state = { loading: false, username: "", title: "", content: "" };
    }

    clear = () => {
        this.setState({
            ...this.state,
            username: this.context.currUsername || "",
            title: "",
            content: ""
        })
    }

    onSubmit = (e) => {
        e.preventDefault();
        const { username, title, content } = this.state;
        this.setState({ ...this.state, loading: true });
        this.context.createPost({ username, title, content }).then(d => {
            if (d.ok) {
                this.setState({ loading: false, errors: {} });
                this.clear();
            } else {
                this.setState({ ...this.state, loading: false, errors: d.errors });
            }
        })
    }

    changeHandler = (key) => {
        return (e) => {
            let val = e.target.value;
            this.setState({ ...this.state, [key]: val })
        }
    }

    displayErrors = (key) => {
        if (this.props.errors && this.props.errors[key]) {
            return (
                <div className="errors">
                    {this.props.errors[key].map((x, i) => <p key={i}>{x}</p>)}
                </div>
            );
        } else {
            return '';
        }
    }

    render() {
        const { loading, username, title, content } = this.state;
        const { currUsername } = this.context;

        return (
            <div className='createPost'>
                {loading ? <div className='loadingOverlay' /> : ''}
                <h1>Make a post</h1>

                <form className='postForm' onSubmit={this.onSubmit}>
                    <div className="input">
                        <input type="text" disabled={currUsername !== undefined} placeholder="username" name="username" value={currUsername || username} onChange={this.changeHandler('username')} />
                        {this.displayErrors('username')}
                    </div>
                    <div className="input">
                        <input type="text" placeholder="title" name="title" value={title} onChange={this.changeHandler('title')} />
                        {this.displayErrors('title')}
                    </div>
                    <div className="input">
                        <textarea type="text" placeholder="content" name="content" value={content} onChange={this.changeHandler('content')} />
                        {this.displayErrors('content')}
                    </div>
                    <input type="submit" value="Post" />
                </form>
            </div>

        )
    }
}

CreatePost.contextType = PostStoreCtx;