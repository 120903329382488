import React from 'react';
import { makeObservable, observable, action, computed, runInAction } from 'mobx';
import { toSvg } from 'jdenticon';
import axios from 'axios';

axios.defaults.withCredentials = true
axios.defaults.baseURL = "https://cf-hiring-worker-production.tcmal.workers.dev"
// axios.defaults.baseURL = "http://127.0.0.1:8787/"

const AVATAR_SIZE = 100;

let NEXT_LOCAL_POST_ID = 0;

export class PostStore {
    posts = []
    nextCursor = undefined
    loadError = undefined
    isLoading = false
    currUsername

    get hasMore() {
        return this.nextCursor !== undefined;
    }

    get currUsername() {
        return this.__currUsername
    }

    set currUsername(val) {
        if (val !== this.__currUsername && val)
            window.localStorage.setItem('username', val)
        this.__currUsername = val
    }

    constructor() {
        makeObservable(this, {
            posts: observable,
            nextCursor: observable,
            hasMore: computed,
            loadError: observable,
            loadMore: action,
            fullRefresh: action,
            createPost: action
        });

        this.currUsername = window.localStorage.getItem('username') || undefined
    }

    fullRefresh = () => {
        this.posts = [];
        this.nextCursor = undefined;
        this.loadError = undefined;
        this.isLoading = false;

        this.loadMore();
    }

    loadMore = async () => {
        if (this.loadError || this.isLoading) {
            return;
        }

        this.isLoading = true;
        
        try {
            const resp = await axios.get("/posts?limit=5&cursor=" + encodeURIComponent(this.nextCursor || ''));
            let posts = resp.data.map(x => ({...x, avatar: toSvg(x.username, AVATAR_SIZE), id: NEXT_LOCAL_POST_ID++}));
        
            runInAction(() => {
                this.posts = [...this.posts, ...posts];
                this.nextCursor = posts[posts.length - 1]._cursor;
                this.isLoading = false;
            })
        } catch (err) {
            runInAction(() => {
                this.loadError = err;
                this.isLoading = false;
            })
        }
    }

    createPost = async (data) => {
        let resp = await axios.post("/posts", data, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            validateStatus: () => true,
            withCredentials: true,
        });

        if (resp.data === 'success') {
            runInAction(async () => {
                this.currUsername = data.username;

                this.fullRefresh();
            })

            return {ok: true};
        } else {
            if (resp.data.errors.username && resp.data.errors.username.length > 0) {
                runInAction(() => {
                    this.currUsername = undefined;
                })
            }
            return resp.data;
        }
    }
}

export const PostStoreCtx = React.createContext(new PostStore());