import React from 'react';
import './Embed.css';

const getDomain = (url) => {
    let a = document.createElement('a');
    a.href = url;
    return a.hostname;
}

const Embed = ({ title, author, url, thumbnail }) => (
    <a className='embedContainer' href={url} target="_blank" rel="noreferrer">
        <div className="embed">
            {thumbnail.url ? <img alt="Thumbnail" className='thumbnail' src={thumbnail.url} /> : ''}
            <div className="details">
                <h2>{title || getDomain(url)}</h2>
                {author.name ? <p>by {author.name}</p> : ''}
                <span className='url'>{url}</span>
            </div>
        </div>
    </a>
)

export default Embed;